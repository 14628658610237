<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: "bg-transparent",
  },
});
</script>

<template>
  <slot />
</template>

<style scoped></style>
