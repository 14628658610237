import { default as admin02XFd05CPxMeta } from "/opt/buildhome/repo/pages/admin.vue?macro=true";
import { default as _91_46_46_46slug_93ZvihMONwlrMeta } from "/opt/buildhome/repo/pages/api/1/[...slug].vue?macro=true";
import { default as _91slug_93x8nwzxMykbMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as blogIjGp6lPWSCMeta } from "/opt/buildhome/repo/pages/blog.vue?macro=true";
import { default as checkoutfrrPZSvI7jMeta } from "/opt/buildhome/repo/pages/checkout.vue?macro=true";
import { default as _91vin_93WUxCsgWOHhMeta } from "/opt/buildhome/repo/pages/console/[vin].vue?macro=true";
import { default as indexOIuozk3f04Meta } from "/opt/buildhome/repo/pages/console/index.vue?macro=true";
import { default as deleteImZtClZEzFMeta } from "/opt/buildhome/repo/pages/delete.vue?macro=true";
import { default as _91_46_46_46slug_93lnRvSSjMOFMeta } from "/opt/buildhome/repo/pages/docs/[...slug].vue?macro=true";
import { default as docs84AgECXoU4Meta } from "/opt/buildhome/repo/pages/docs.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as homeassistantbmuMFPaeCkMeta } from "/opt/buildhome/repo/pages/feature/homeassistant.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as indexaZBQWz0qbQMeta } from "/opt/buildhome/repo/pages/obs/[vin]/[signal]/index.vue?macro=true";
import { default as index8wx8rg8iZXMeta } from "/opt/buildhome/repo/pages/partners/index.vue?macro=true";
import { default as tintoabkIOxNxBMeta } from "/opt/buildhome/repo/pages/partners/tint.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as redirectx0QV1tiGZfMeta } from "/opt/buildhome/repo/pages/redirect.vue?macro=true";
import { default as teslabillyrXRgka8iiMeta } from "/opt/buildhome/repo/pages/teslabill.vue?macro=true";
import { default as component_45stubFnMBeAcAeNMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFnMBeAcAeN } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: admin02XFd05CPxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin.vue")
  },
  {
    name: "api-1-slug",
    path: "/api/1/:slug(.*)*",
    meta: _91_46_46_46slug_93ZvihMONwlrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/api/1/[...slug].vue")
  },
  {
    name: blogIjGp6lPWSCMeta?.name,
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog.vue"),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  }
]
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutfrrPZSvI7jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout.vue")
  },
  {
    name: "console-vin",
    path: "/console/:vin()",
    meta: _91vin_93WUxCsgWOHhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/[vin].vue")
  },
  {
    name: "console",
    path: "/console",
    meta: indexOIuozk3f04Meta || {},
    component: () => import("/opt/buildhome/repo/pages/console/index.vue")
  },
  {
    name: "delete",
    path: "/delete",
    component: () => import("/opt/buildhome/repo/pages/delete.vue")
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/opt/buildhome/repo/pages/docs.vue"),
    children: [
  {
    name: "docs-slug",
    path: ":slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/docs/[...slug].vue")
  }
]
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/buildhome/repo/pages/error.vue")
  },
  {
    name: "feature-homeassistant",
    path: "/feature/homeassistant",
    component: () => import("/opt/buildhome/repo/pages/feature/homeassistant.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "obs-vin-signal",
    path: "/obs/:vin()/:signal()",
    meta: indexaZBQWz0qbQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/obs/[vin]/[signal]/index.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/opt/buildhome/repo/pages/partners/index.vue")
  },
  {
    name: "partners-tint",
    path: "/partners/tint",
    component: () => import("/opt/buildhome/repo/pages/partners/tint.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectx0QV1tiGZfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/redirect.vue")
  },
  {
    name: "teslabill",
    path: "/teslabill",
    component: () => import("/opt/buildhome/repo/pages/teslabill.vue")
  },
  {
    name: component_45stubFnMBeAcAeNMeta?.name,
    path: "/api/fields.json",
    component: component_45stubFnMBeAcAeN
  },
  {
    name: component_45stubFnMBeAcAeNMeta?.name,
    path: "/qr",
    component: component_45stubFnMBeAcAeN
  }
]