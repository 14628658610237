import payload_plugin_VSTkPdNd4m from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_firebase@11.1.0_magicast@0_qtfbjh2s4inupavacr2hs6ajpi/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_a5rY0FX8K9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HNQGIxeY0F from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NlHDZllTCP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2ACa5OkQKP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5._i3g5vaqwrwdvlqvjnwf4xp2ecy/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_5IOHhY5UW8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QZ2o1CdOnE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qiMpNrfbpd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zuEWpdAREx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_u5muBASQu7 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_7aK9RseDHP from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.47.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.0_@opentelemetry+api@1._f2yee7n3nwz3s464tihiockxie/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_mpc73G9ajX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.0.2_firebase@11.1.0_magicast@0_qtfbjh2s4inupavacr2hs6ajpi/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import siteConfig_lZXKqtXcSK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_O4ekS5GzVq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_EhYSpiBhEa from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import slideovers_FcLxvkc2hh from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript@5._35fgkov3qutvrdttzwtvri4swq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_AbJRIbP4Jn from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript@5._35fgkov3qutvrdttzwtvri4swq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_9GArjwRETV from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript@5._35fgkov3qutvrdttzwtvri4swq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_HpZWMCBLbA from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_lSDjrxwod1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37.0__kzmlkneuy44hpc2kn2wzylmvwq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_rFJHEw5AGd from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript_dfo55dbtam2jnwrjtfrimjeqxa/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_STGFVjXy98 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript_dfo55dbtam2jnwrjtfrimjeqxa/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_N20xa6sWMW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.29.1_typescript_dfo55dbtam2jnwrjtfrimjeqxa/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_6OSkSWlVfm from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
import defaults_mNcOUp94Cl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_terser@5.37_kytubs7zq52d3thmuvc3vgli4e/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_VSTkPdNd4m,
  revive_payload_client_a5rY0FX8K9,
  unhead_HNQGIxeY0F,
  router_NlHDZllTCP,
  _0_siteConfig_2ACa5OkQKP,
  payload_client_5IOHhY5UW8,
  navigation_repaint_client_QZ2o1CdOnE,
  check_outdated_build_client_qiMpNrfbpd,
  chunk_reload_client_zuEWpdAREx,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_u5muBASQu7,
  sentry_client_7aK9RseDHP,
  sentry_client_config_o34nk2sJbg,
  plugin_client_mpc73G9ajX,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  siteConfig_lZXKqtXcSK,
  inferSeoMetaPlugin_O4ekS5GzVq,
  titles_EhYSpiBhEa,
  slideovers_FcLxvkc2hh,
  modals_AbJRIbP4Jn,
  colors_9GArjwRETV,
  plugin_client_HpZWMCBLbA,
  plugin_lSDjrxwod1,
  scrollbars_client_rFJHEw5AGd,
  presets_STGFVjXy98,
  variables_N20xa6sWMW,
  paddleJS_client_6OSkSWlVfm,
  defaults_mNcOUp94Cl
]