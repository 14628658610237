import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/pages/runtime/validate.js";
import log_45global from "/opt/buildhome/repo/middleware/log.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2___gl4p7i7e7l5klnlyiijxpmqywi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/opt/buildhome/repo/middleware/admin.ts"),
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts"),
  ptxn: () => import("/opt/buildhome/repo/middleware/ptxn.ts")
}