<script setup></script>

<template>
  <div class="text-center">
    <p class="text-gray-500 dark:text-gray-400 text-xl">Uptime</p>
    <p class="value text-6xl text-primary-500 dark:text-primary-400 font-bold">
      99.9%
    </p>
    <br />
    <p class="text-gray-500 dark:text-gray-400 text-xl">Server Regions</p>
    <p class="value text-primary-500 dark:text-primary-400 text-6xl font-bold">
      4
    </p>
    <br />
    <p class="text-gray-500 dark:text-gray-400 text-xl">API Endpoints</p>
    <p class="value text-primary-500 dark:text-primary-400 text-6xl font-bold">
      115
    </p>
  </div>
</template>

<style scoped>
.value {
  font-family: Roboto, sans-serif;
}
</style>
